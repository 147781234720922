import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//获取所有的路由列表
export const getMenus = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/UserRole/GetMenus`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}